import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from 'react-redux';
import {HeaderFinal} from "./containers/Header/HeaderFinal";
import './styles/index.scss';
import configureStore from "./store/configureStore";
import {requestConfig} from "./store/config";
import {requestResults, requestVariant} from "./store/survey";

const store = configureStore();

const App = () => {
    store.dispatch(requestConfig());
    store.dispatch(requestResults());
    store.dispatch(requestVariant());
    return (
        <Provider store={store}>
            <HeaderFinal/>
            {/*<Header/>*/}
            {/*<FavoriteClub/>*/}
            {/*<Goals/>*/}
            {/*<Survey/>*/}
            {/*<Footer/>*/}
        </Provider>
    );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App/>
);
