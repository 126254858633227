import React from 'react';
import b from './Headline.module.scss';
import classNames from 'classnames/bind';

let cx = classNames.bind(b);

export const Headline = (props) => {
    const {dark= false, children} = props;
    return (
        <div className={cx(props.className, {
            headline: true,
            dark: dark
        })}>{children}</div>
    );
}
