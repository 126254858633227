import React from "react";
import b from './Header.module.scss'
import winlineLogo from './assets/logo/logo-winline.png'
import winlineLogo2x from './assets/logo/logo-winline@2x.png'
import spartakLogo from './assets/logo/logo-spartak.png'
import spartakLogo2x from './assets/logo/logo-spartak@2x.png'
import {Headline} from "../../components/headline/Headline";
import composition_1 from "./assets/winners/composition-1.png";
import composition1_2x from "./assets/winners/composition-1@2x.png";
// import {useSelector} from "react-redux";
// import {getWinnerVariant} from "../../store/survey";

export const HeaderFinal = () => {
    // const winner = useSelector(getWinnerVariant);
    return (
        <div className={`${b.container} ${b.containerFinal}`}>
            <div className={b.logosContainer}>
                <div className="medium-margin">
                    <div className="row">
                        <div className="column large-12">
                            <div className={b.logos}>
                                <a className={b.winlineLogo}
                                   target="_blank"
                                   href="https://clck.ru/35EmLg"
                                   rel="noreferrer">
                                    <img
                                        src={winlineLogo} srcSet={`${winlineLogo2x} 2x`}
                                        draggable="false"
                                        alt="Winline"
                                    />
                                </a>
                                <a className={b.spartakLogo} target="_blank" href="https://spartak.com"
                                   rel="noreferrer">
                                    <img
                                        src={spartakLogo} srcSet={`${spartakLogo2x} 2x`}
                                        draggable="false"
                                        alt="ФК «Спартак-Москва»"
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="medium-margin">
                <div className="row">
                    <div className="column small-12 medium-12 large-12 position-relative">
                        <div className={b.preHeadlineContainer}>
                            <div className={b.tag}>
                                <span>Голосование завершено</span>
                            </div>
                        </div>
                        <div className={b.headlineContainer}>
                            <Headline className={b.headline}>
                                Выбор <br className="show-for-medium-only"/> сделан
                            </Headline>
                        </div>
                        <div className={`${b.composition}`}>
                            <img
                                src={composition_1} srcSet={`${composition1_2x} 2x`}
                                draggable="false"
                                alt=""
                            />
                        </div>
                        <div className={b.surveyResultsContainer}>
                            <div className={b.surveyResults}>
                                <div className={b.results}>
                                    <div className={b.variantPercentage}>45%</div>
                                    <div className={b.variantTitle}>
                                        ФУТБОЛЬНО-МУЗЫКАЛЬНЫЙ ФЕСТИВАЛЬ
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
